import React, { useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Close } from '@videoblocks/react-icons';

import { StockItemFormat } from '../../../../../common/types/StockItemTypes';

interface VideoStockItemDownloaderProps {
  stockItemFormats: StockItemFormat[];
  onSelect?: (event, format: StockItemFormat) => void;
  onClose: (event) => void;
}
const VideoStockItemDownloader = ({
  stockItemFormats,
  onSelect = (event, format) => {},
  onClose,
}: VideoStockItemDownloaderProps) => {
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [isContainerHovered, setIsContainerHovered] = useState(false);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(() => {
    // Try to get initial width during mounting
    return containerRef.current?.getBoundingClientRect().width || 500; // Default to non-compact
  });

  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, []);

  console.log('containerWidth', containerWidth);

  const formatsByResolution = useMemo(() => {
    return stockItemFormats.reduce(
      (acc, format) => {
        const key = format.resolution;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(format);
        return acc;
      },
      {} as Record<string, StockItemFormat[]>
    );
  }, [stockItemFormats]);

  const shouldShowSelectedState = !isContainerHovered;
  const isCardSmallerSize = containerWidth < 350;
  const isCardMediumSize = containerWidth < 448;

  return (
    <div
      ref={containerRef}
      className="download-format-selector view bg-gray-900 bg-opacity-90 rounded-lg h-full flex justify-center items-center"
    >
      <button
        className={twMerge(
          'absolute right-5 top-5 text-white border-0 z-10',
          `${isCardSmallerSize ? 'top-1.5 right-3' : ''}`
        )}
        onClick={onClose}
        name={'close'}
      >
        <Close className="w-4 h-4" fill="white" />
      </button>
      <div className="flex flex-col w-full relative items-center">
        <h2
          className={`text-white text-base text-center mt-0 ${
            isCardSmallerSize ? 'mb-0' : 'mb-4'
          }`}
        >
          Download
        </h2>
        <div
          className={twMerge(
            `flex justify-center gap-3 w-fit mx-2`,
            `${isCardSmallerSize ? 'flex-col w-full gap-1.5 px-3' : ''}`
          )}
          onMouseEnter={() => setIsContainerHovered(true)}
          onMouseLeave={() => setIsContainerHovered(false)}
        >
          {Object.values(formatsByResolution).flatMap((formats) =>
            formats.map((format) => {
              const formatId = `${format.formatName}-${format.resolution}`;
              const isSelected =
                selectedFormat?.formatName === format.formatName &&
                selectedFormat?.resolution === format.resolution;
              const isHovered = hoveredId === formatId;
              const isActive =
                (isSelected && shouldShowSelectedState) || isHovered;

              const DownloadButton = isCardSmallerSize
                ? FormatButtonSmall
                : FormatButton;

              return (
                <DownloadButton
                  key={formatId}
                  format={format}
                  formatId={formatId}
                  isActive={isActive}
                  onSelect={(event) => {
                    setSelectedFormat(format);
                    onSelect(event, format);
                  }}
                  onHoverStart={() => setHoveredId(formatId)}
                  onHoverEnd={() => setHoveredId(null)}
                  isCompact={isCardMediumSize}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

interface FormatButtonProps {
  format: StockItemFormat;
  formatId: string;
  isActive: boolean;
  onSelect: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onHoverStart: (formatId: string) => void;
  onHoverEnd: () => void;
  isCompact?: boolean;
}
const FormatButton = ({
  format,
  formatId,
  isActive,
  onSelect,
  onHoverStart,
  onHoverEnd,
  isCompact = false,
}: FormatButtonProps) => {
  const textColorClass = isActive ? 'text-gray-900' : 'text-white';
  const secondaryTextClass = isActive ? 'text-gray-900' : 'text-gray-400';

  return (
    <button
      onClick={onSelect}
      className={`flex flex-col items-center justify-center py-2
                 rounded-xl border border-white border-solid
                 hover:bg-white hover:opacity-90
                 ${isActive ? 'bg-white hover:opacity-100' : ''}`}
      onMouseEnter={() => onHoverStart(formatId)}
      onMouseLeave={onHoverEnd}
      style={{ width: `${isCompact ? '77px' : '99px'}` }}
    >
      <span className={`text-2xl font-semibold mb-1 ${textColorClass}`}>
        {format.quality}
      </span>
      <div className={`text-xs font-normal ${textColorClass}`}>
        <span className="uppercase">{format.fileExtension}</span>
      </div>
      <div className={`text-xs font-normal ${textColorClass} min-w-14`}>
        {`(${format.codec ? format.codec : '(unknown)'})`}
      </div>
      <div className={`text-xs font-normal ${secondaryTextClass}`}>
        {format.filesize}
      </div>
    </button>
  );
};

const FormatButtonSmall = ({
  format,
  formatId,
  isActive,
  onSelect,
  onHoverStart,
  onHoverEnd,
}: FormatButtonProps) => {
  const textColorClass = isActive ? 'text-gray-900' : 'text-white';
  const secondaryTextClass = isActive ? 'text-gray-900' : 'text-gray-400';

  return (
    <button
      onClick={onSelect}
      className={`flex items-center justify-center
                 rounded-md border border-white border-solid
                 hover:bg-white hover:opacity-90 w-full gap-1
                 ${isActive ? 'bg-white hover:opacity-100' : ''}`}
      onMouseEnter={() => onHoverStart(formatId)}
      onMouseLeave={onHoverEnd}
      style={{ height: `20px` }}
    >
      <span className={`text-sm font-semibold uppercase ${textColorClass}`}>
        {format.fileExtension}
      </span>
      <span
        className={`text-xs ${textColorClass} whitespace-nowrap overflow-hidden text-ellipsis`}
      >
        {`${format.quality}${(format.fileExtension || '').toUpperCase()} (${
          format.codec
        }) - ${format.filesize}`}
      </span>
    </button>
  );
};

export default VideoStockItemDownloader;
